import React, { useContext, useEffect, useState } from 'react'
import { GoogleMap, useLoadScript } from '@react-google-maps/api'
import { Libraries } from '@react-google-maps/api/dist/utils/make-load-script-url'
import { CloseButton, FirstBlock, LocalizationBar, LocalizationContainer, LocationButton, SecondBlock } from './style'
import Loader from '../Loader'
import downArrowIcon from '../../assets/images/down-arrow.svg'
import infoIcon from '../../assets/images/info.svg'
import CitySelector from './CitySelector'
import LocationContext from '../../context/LocationContext'
import { GOOGLE_API_KEY } from '../../config'

const libs: Libraries = ['places']

export const cities = ['Quito', 'Guayaquil', 'Cuenca'] 

export default function Localization() {
  const { isLoaded } = useLoadScript({ googleMapsApiKey: GOOGLE_API_KEY, libraries: libs, language: 'es-419', region: 'ec' })
  const [openCitySelector, isOpenCitySelector] = useState(false)
  const [loader, showLoader] = useState(false)
  const { city, loadCity, withCoverage, hasCoverage, navigated } = useContext(LocationContext)
  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  }

  useEffect(() => {
    if (city) {
      hasCoverage && hasCoverage(true)
    }

    if (!withCoverage && !navigated && !city) {
      if (navigator.geolocation && navigator.permissions) {
        navigator.permissions.query({ name: 'geolocation' }).then(function (result) {
          if (result.state === 'granted') {
            navigator.geolocation.getCurrentPosition(defineInitialCity)
          } else if (result.state === 'prompt') {
            navigator.geolocation.getCurrentPosition(defineInitialCity, errors, options)
          } else if (result.state === 'denied') {
            navigator.geolocation.getCurrentPosition(errors)
          }
        })
        return
      }

      isOpenCitySelector(true)
    }
  }, [])

  useEffect(() => {
    if (city) {
      hasCoverage && hasCoverage(true)
    }
  }, [city])

  function errors(err: any) {
    console.warn(`ERROR(${err.code}): ${err.message}`)
    hasCoverage && hasCoverage(false)
    showLoader(false)
    isOpenCitySelector(true)
  }

  const defineInitialCity = (pos: any) => {
    const { coords } = pos
    const { latitude, longitude } = coords

    const geocoder = new google.maps.Geocoder()

    showLoader(true)
    if (city) {
      loadCity && loadCity(city)
      hasCoverage && hasCoverage(true)
      showLoader(false)
      return
    }

    geocoder.geocode({ location: { lat: latitude, lng: longitude } }, (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
      if (!results || !results[0]) {
        loadCity && loadCity('')
        showLoader(false)
        return
      }
      if (status === 'OK' && results[0]) {
        const currentCity = results[0].address_components.filter(addressComponent => addressComponent.types[0] === 'locality')[0].short_name
        const grandeTableCity = cities.filter(city => city == currentCity)[0]
        showLoader(false)
        if (grandeTableCity) {
          hasCoverage && hasCoverage(true)
          loadCity && loadCity(grandeTableCity)
          return
        }
        hasCoverage && hasCoverage(false)
        isOpenCitySelector(true)
      }
    })
  }

  return loader ? (
    <Loader />
  ) : (
    <>
      {withCoverage ? (
        <LocalizationBar>
          <LocalizationContainer>
            <FirstBlock>
              <div className="gt-city-sel">Lo mejor de Grande Table en:</div>
              <LocationButton onClick={() => isOpenCitySelector(true)}>
                {isLoaded && (
                  <GoogleMap id="google-map-container" mapContainerClassName="relative-position-first-child">
                    <label>{city}</label>
                  </GoogleMap>
                )}
                <img src={downArrowIcon} className="drop-down-icon" />
              </LocationButton>
            </FirstBlock>
          </LocalizationContainer>
        </LocalizationBar>
      ) : (
        <LocalizationBar error>
          <LocalizationContainer className="blocks">
            <div>
              <FirstBlock>
                <div className="message">
                  <img className="info" src={infoIcon} alt="info" />
                  Al parecer te encuentras fuera de nuestra zona de cobetura
                </div>
              </FirstBlock>
              <SecondBlock>
                <LocationButton
                  white
                  onClick={() => {
                    if (!city) {
                      isOpenCitySelector(true)
                      return
                    }
                  }}
                >
                  Cambiar ubicación
                </LocationButton>
              </SecondBlock>
            </div>
            <CloseButton onClick={() => hasCoverage && hasCoverage(true)}>
              <i className="icon-cancel" />
            </CloseButton>
          </LocalizationContainer>
        </LocalizationBar>
      )}
      <CitySelector open={openCitySelector} closeModal={() => isOpenCitySelector(false)} />
    </>
  )
}
